import React from 'react'
import { SectionTwoImages } from '../../../types/sections'
import { SECTION_MARGIN } from '../../../utils/constants'
import { Box, Image } from '../../../atoms'
import { Grid, Row, Col } from 'react-styled-flexboxgrid'

function TwoImages(props: SectionTwoImages): JSX.Element {
  const { id, sectionId, images } = props
  return (
    <Box as={'section'} id={sectionId || id} my={SECTION_MARGIN}>
      <Grid>
        <Row>
          {images.map((image, idx) => {
            return (
              <Col key={idx} xs={12} md={6}>
                <Box
                  mt={idx === 1 ? [6, 6, 10] : undefined}
                  mx={idx === 1 ? [4, 4, 0] : undefined}
                  pr={idx === 0 ? [0, 0, 4] : undefined}
                  pl={idx === 1 ? [0, 0, 4] : undefined}
                >
                  <Box borderRadius={'small'} overflow={'hidden'}>
                    <Image alt={image.alt ?? ''} title={image.title ?? ''} image={image.gatsbyImageData} />
                  </Box>
                </Box>
              </Col>
            )
          })}
        </Row>
      </Grid>
    </Box>
  )
}

export default TwoImages
